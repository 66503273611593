import gql from 'graphql-tag';


export const GET_SEARCH = gql`
    query (
        $hospitalUuid: ID!,
        $search: String,
        $createdAtAfter: DateTime,
        $createdAtBefore: DateTime,
        $updatedAtAfter: DateTime,
        $updatedAtBefore: DateTime,
        $first: Int,
        $patientsCursor: String,
        $casesCursor: String,
        $studiesCursor: String
    ) {
        patients(
            hospitalUuid: $hospitalUuid,
            search: $search,
            createdAtAfter: $createdAtAfter,
            createdAtBefore: $createdAtBefore,
            updatedAtAfter: $updatedAtAfter,
            updatedAtBefore: $updatedAtBefore,
            first: $first,
            after: $patientsCursor
        ) {
            totalCount
            pageInfo {
                endCursor
                hasNextPage
            }
            edges {
                cursor
                node {
                    uuid
                    customId
                    name
                    surname
                    createdAt
                    updatedAt
                    medicalCases {
                        title
                        uuid
                    }
                }
            }
        }
        medicalCases(
            hospitalUuid: $hospitalUuid,
            search: $search,
            createdAtAfter:$createdAtAfter,
            createdAtBefore: $createdAtBefore,
            updatedAtAfter: $updatedAtAfter,
            updatedAtBefore: $updatedAtBefore,
            first: $first,
            after: $casesCursor
        ) {
            totalCount
            pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
            }
            edges {
                cursor
                node {
                    uuid
                    title
                    description
                    specialty
                    createdBy
                    createdAt
                    updatedAt
                    patient {
                        uuid
                        name
                        surname
                    }
                    studies {
                        uuid
                        title
                        createdAt
                        numberOfSeries
                        numberOfInstances
                        modalities
                    }
                }
            }
        }
        dicomStudies(
            hospitalUuid: $hospitalUuid,
            search: $search,
            createdAtAfter: $createdAtAfter,
            createdAtBefore: $createdAtBefore,
            updatedAtAfter: $updatedAtAfter,
            updatedAtBefore: $updatedAtBefore,
            first: $first,
            after: $studiesCursor
        ) {
            totalCount
            pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
            }
            edges {
                cursor
                node {
                    uuid
                    title
                    owner
                    createdAt
                    updatedAt
                    description
                    medicalCase {
                        uuid
                        createdBy
                    }
                    studyInstanceUid
                    numberOfSeries
                    numberOfInstances
                    modalities
                }
            }
        }
        hospitalStudies(
            hospitalUuid: $hospitalUuid,
            search: $search,
            createdAtAfter: $createdAtAfter,
            createdAtBefore: $createdAtBefore,
            updatedAtAfter: $updatedAtAfter,
            updatedAtBefore: $updatedAtBefore,
            first: $first,
            after: $studiesCursor
        ) {
            totalCount
            pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
            }
            edges {
                cursor
                node {
                    uuid
                    title
                    owner
                    createdAt
                    updatedAt
                    description
                    hospital {
                        uuid
                        name
                    }
                    studyInstanceUid
                    numberOfSeries
                    numberOfInstances
                    modalities
                }
            }
        }
    }
`;

export const GET_TELEMEDICINE_SEARCH = gql`
    query (
        $hospitalUuid: ID!,
        $patientUuid: ID,
        $search: String,
        $createdAtAfter: DateTime,
        $createdAtBefore: DateTime,
        $updatedAtAfter: DateTime,
        $updatedAtBefore: DateTime,
        $first: Int,
        $casesCursor: String,
        $studiesCursor: String
    ) {
        medicalCases(
            hospitalUuid: $hospitalUuid,
            patientUuid: $patientUuid,
            search: $search,
            createdAtAfter:$createdAtAfter,
            createdAtBefore: $createdAtBefore,
            updatedAtAfter: $updatedAtAfter,
            updatedAtBefore: $updatedAtBefore,
            first: $first,
            after: $casesCursor
        ) {
            totalCount
            pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
            }
            edges {
                cursor
                node {
                    uuid
                    title
                    description
                    specialty
                    createdBy
                    createdAt
                    updatedAt
                    studies {
                        uuid
                        title
                        createdAt
                        numberOfSeries
                        numberOfInstances
                        modalities
                    }
                }
            }
        }
        dicomStudies(
            patientUuid: $patientUuid,
            search: $search,
            createdAtAfter: $createdAtAfter,
            createdAtBefore: $createdAtBefore,
            updatedAtAfter: $updatedAtAfter,
            updatedAtBefore: $updatedAtBefore,
            first: $first,
            after: $studiesCursor
        ) {
            totalCount
            pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
            }
            edges {
                cursor
                node {
                    uuid
                    title
                    owner
                    createdAt
                    updatedAt
                    description
                    medicalCase {
                        uuid
                        createdBy
                    }
                    studyInstanceUid
                    numberOfSeries
                    numberOfInstances
                    modalities
                }
            }
        }
    }
`;

export const GET_PATIENTS_QUERY = gql`
    query ($hospitalUuid: ID!, $search: String, $createdAtAfter: DateTime, $createdAtBefore: DateTime, $updatedAtAfter: DateTime, $updatedAtBefore: DateTime, $first: Int, $cursor: String) {
        patients(hospitalUuid: $hospitalUuid, search: $search, createdAtAfter: $createdAtAfter, createdAtBefore: $createdAtBefore, updatedAtAfter: $updatedAtAfter, updatedAtBefore: $updatedAtBefore, first: $first, after: $cursor) {
            totalCount
            pageInfo {
                endCursor
                hasNextPage
            }
            edges {
                cursor
                node {
                    uuid
                    customId
                    name
                    surname
                    medicalCases {
                        title
                        uuid
                    }
                }
            }
        }
    }
`;

export const GET_CASES_QUERY = gql`
    query ($hospitalUuid: ID!, $search: String, $createdAtAfter: DateTime, $createdAtBefore: DateTime, $updatedAtAfter: DateTime, $updatedAtBefore: DateTime, $first: Int, $cursor: String) {
        medicalCases(hospitalUuid: $hospitalUuid, search: $search, createdAtAfter: $createdAtAfter, createdAtBefore: $createdAtBefore, updatedAtAfter: $updatedAtAfter, updatedAtBefore: $updatedAtBefore, first: $first, after: $cursor) {
            totalCount
            pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
            }
            edges {
                cursor
                node {
                    uuid
                    title
                    description
                    specialty
                    createdBy
                    createdAt
                    updatedAt
                    patient {
                        uuid
                        name
                        surname
                    }
                    studies {
                        uuid
                        title
                        createdAt
                        numberOfSeries
                        numberOfInstances
                        modalities
                    }
                    comments {
                        uuid
                        createdAt
                        body
                    }
                    collaborations {
                        uuid
                        createdAt
                        updatedAt
                        permission
                    }
                }
            }
        }
    }
`;

export const GET_STUDIES_QUERY = gql`
    query ($search: String, $createdAtAfter: DateTime, $createdAtBefore: DateTime, $updatedAtAfter: DateTime, $updatedAtBefore: DateTime, $first: Int, $cursor: String) {
        dicomStudies(search: $search, createdAtAfter: $createdAtAfter, createdAtBefore: $createdAtBefore, updatedAtAfter: $updatedAtAfter, updatedAtBefore: $updatedAtBefore, first: $first, after: $cursor) {
            totalCount
            pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
            }
            edges {
                cursor
                node {
                    uuid
                    title
                    owner
                    createdAt
                    updatedAt
                    description
                    medicalCase {
                        uuid
                        createdBy
                    }
                    studyInstanceUid
                    numberOfSeries
                    numberOfInstances
                    modalities
                }
            }
        }
    }
`;

export const GET_HOSPITAL_STUDIES_QUERY = gql`
    query ($hospitalUuid: ID!, $search: String, $createdAtAfter: DateTime, $createdAtBefore: DateTime, $updatedAtAfter: DateTime, $updatedAtBefore: DateTime, $first: Int, $cursor: String) {
        hospitalStudies(hospitalUuid: $hospitalUuid, search: $search, createdAtAfter: $createdAtAfter, createdAtBefore: $createdAtBefore, updatedAtAfter: $updatedAtAfter, updatedAtBefore: $updatedAtBefore, first: $first, after: $cursor) {
            totalCount
            pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
            }
            edges {
                cursor
                node {
                    uuid
                    title
                    owner
                    createdAt
                    updatedAt
                    description
                    hospital {
                        uuid
                        name
                    }
                    studyInstanceUid
                    numberOfSeries
                    numberOfInstances
                    modalities
                }
            }
        }
    }
`;
