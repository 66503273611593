import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  relative: {
    position: 'relative',
    width: '100%',
  },
  filters: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 'calc(100% - 20px)',
    height: 48,
    padding: 6,
    paddingLeft: 10,
    backgroundColor: '#efefef',
    borderBottom: 'solid 1px #00000018',
    zIndex: 4,
    '& .MuiAccordion-root': {
      zIndex: 20,
    },
    '@media (max-width:600px)': {
      height: 96,
    },
  },
  result: {
    padding: 24,
    paddingTop: 44,
    paddingBottom: 0,
    '@media (max-width:600px)': {
      paddingTop: 96,
      padding: 16,
    },
  },
}));

export default useStyles;
