import React from 'react';
import { useTranslation } from 'react-i18next';
import { Accordion, AccordionSummary, AccordionDetails, Grid, makeStyles, Typography } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';

import { DateTimeFieldUI } from '../../componentsUI/DateTimeField';
import { getDateTextFromDateInstance } from '../../utils/dateTimeUtils';

const useStyles = makeStyles(() => ({
  root: {
    '&.MuiAccordion-rounded:first-child': {
      borderRadius: 0,
    },
    '& .MuiFormControl-root': {
      minWidth: '100%',
    },
  },
  summary: {
    fontWeight: 700,
  },
}));

export const SearchFilters = ({ filters, updateFilter }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const minDate = new Date('2020-01-01');
  const today = new Date();

  const updateDate = (field, value) => {
    if (!field) return;
    if (!value) updateFilter(field, null);

    const date = getDateTextFromDateInstance(value);
    if (!date) return;

    updateFilter(field, date);
  };

  return (
    <Grid container>
      <Grid item sm={6} xs={12}>
        <Accordion className={classes.root}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="date-create-content"
            id="date-create-header"
          >
            <Typography className={classes.summary}>
              {t('date.creation.interval')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <DateTimeFieldUI
                  name="createdAtAfter"
                  minDate={minDate}
                  maxDate={filters.createdAtBefore}
                  iconPosition="right"
                  label={t('start.date')}
                  props={{
                    values: {
                      createdAtAfter: filters.createdAtAfter,
                    },
                    setFieldValue: updateDate,
                    errors: {
                      createdAtAfter: null,
                    },
                  }}
                  classes={classes}
                />
              </Grid>
              <Grid item xs={6}>
                <DateTimeFieldUI
                  name="createdAtBefore"
                  minDate={filters.createdAtAfter}
                  maxDate={today}
                  iconPosition="right"
                  label={t('end.date')}
                  props={{
                    values: {
                      createdAtBefore: filters.createdAtBefore,
                    },
                    setFieldValue: updateDate,
                    errors: {
                      createdAtBefore: null,
                    },
                  }}
                  classes={classes}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>

      <Grid item sm={6} xs={12}>
        <Accordion className={classes.root}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="date-update-content"
            id="date-update-header"
          >
            <Typography className={classes.summary}>
              {t('date.update.interval')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <DateTimeFieldUI
                  name="updatedAtAfter"
                  minDate={minDate}
                  maxDate={filters.updatedAtBefore}
                  iconPosition="right"
                  label={t('start.date')}
                  props={{
                    values: {
                      updatedAtAfter: filters.updatedAtAfter,
                    },
                    setFieldValue: updateDate,
                    errors: {
                      updatedAtAfter: null,
                    },
                  }}
                  classes={classes}
                />
              </Grid>
              <Grid item xs={6}>
                <DateTimeFieldUI
                  name="updatedAtBefore"
                  minDate={filters.updatedAtAfter}
                  maxDate={today}
                  iconPosition="right"
                  label={t('end.date')}
                  props={{
                    values: {
                      updatedAtBefore: filters.updatedAtBefore,
                    },
                    setFieldValue: updateDate,
                    errors: {
                      updatedAtBefore: null,
                    },
                  }}
                  classes={classes}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>

    </Grid>
  );
};
