import React from 'react';

import Layout from '../../components/MenuLayout';
import { SearchView } from '../../components/Search/SearchView';

export default () => (
  <Layout>
    <SearchView />
  </Layout>
);
